<template>
  <div class="pricing">
    <div class="row">
      <div class="col-sm-12">
        <div class="section_title">
          <i class="fa el-icon-shopping-cart-1"></i>
          <h3 class="fadeIn animated wow">服务价格</h3>
          <h6 class="fadeIn animated wow">按需开通，按需支付，灵活部署</h6>
        </div>
      </div>
    </div>
    <div class="card">
      <el-row :gutter="20" type="flex" justify="center">
        <el-col :span="7" v-for="(item, index) in pricingList" :key="index">
          <el-card>
            <div class="item" @mouseenter="enter(index)" @mouseleave="leave(index)">
              <div :class="[isActive&& cindex==index ? 'active' : '' ]">{{ item.version }}</div>
              <h5>{{ item.price }}</h5>
              <div class="crowd">{{ item.crowd }}</div>
              <hr />
              <div
                class="energy"
                v-for="(res, index) in item.func"
                :key="index"
              >
                {{ res }}
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pricingList: [],
      isActive: false,
      cindex: null,
    };
  },
  created() {
    this.pricingList = [
      {
        version: "基础版",
        price: "3000/年",
        crowd: "适用于初创团队、个人创业",
        func: [
          "客户管理",
          "客户端下单",
          "运单管理",
          "财务账单",
          "数据统计",
          "自动追踪",
          "PDA支持",
          "免费培训",
          "免费部署",
          "QQ在线支持",
        ],
      },
      {
        version: "标准版",
        price: "2000/月",
        crowd: "适用于跨境专线物流企业",
        func: [
          "基础功能",
          "对接各大快递",
          "对接清关代理",
          "客户API对接",
          "自动化设备支持",
          "多语言",
          "多币种",
          "免费培训",
          "免费部署",
          "7X12小时电话/QQ在线支持",
        ],
      },
      {
        version: "进阶版",
        price: "5000/月",
        crowd: "适用于多分公司物流企业",
        func: [
          "基础功能",
          "清关代理模块",
          "对接各大快递",
          "对接清关代理",
          "客户API对接",
          "自动化设备支持",
          "多语言",
          "多币种",
          "技术上门",
          "7X12小时电话/QQ支持",
        ],
      },
    ];
  },
  mounted() {},
  methods: {
    enter(index) {
      this.isActive = true;
      this.cindex = index;
    },
    leave(index) {
      this.isActive = false;
      this.cindex = null;
    },
  },
};
</script>

<style lang="less" scoped>
.pricing {
  width: 1200px;
  margin: 50px auto;
}
.row {
  .col-sm-12 {
    width: 100%;
    .section_title {
      text-align: center;
      i {
        color: #0099ff;
        font-size: 36px;
        // margin-bottom: 20px;
      }
      .fa {
        display: inline-block;
        font-family: FontAwesome;
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      h3 {
        font-weight: 500;
        font-size: 32px;
        font-family: "Roboto", sans-serif;
      }
      .fadeIn {
        -webkit-animation-name: fadeIn;
        animation-name: fadeIn;
      }
      .animated {
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
      }
    }
  }
}
.card {
  margin-left: 10px !important;
  margin-right: -26px !important;
  .item {
    // margin: 15px 30px;
    text-align: center;
    h5 {
      margin: 0 0;
      font-size: 56px;
      font-family: "Roboto", sans-serif;
      color: #585858;
      font-weight: 400;
    }
    .crowd {
      color: #000000;
      font-size: 14px;
    }
    .energy {
      margin: 15px 0px;
      color: #999;
    }
  }
}
.active {
  color: #0099ff;
  font-weight: 600;
}
</style>