<template>
  <div>
    <div class="footer">
      <div class="content">
        <el-row :gutter="20" >
          <el-col :span="8">
            <h4 class="logo">荣猿科技</h4>
            <p>
              荣猿科技助你从传统物流向现代智能物流转换，利用信息化系统，智能设备，自动化设备建立标准化的操作流程、
              可追溯网络系统、物流过程的可视化、智能管理网络体系、智能化的物流配送中心，为客户提供更及时、精准、有效的服务
            </p>
          </el-col>
          <el-col :span="7" class="footer_header">
            <ul class="list-unstyled footer-list">
              <h4>网站导航</h4>
              <li v-for="(item, key) in anchor" :key="key">
                <a href="" @click.prevent="scrollDom(key)">{{ item }}</a>
              </li>
            </ul>
          </el-col>
          <el-col :span="5" class="About_us">
            <ul class="Contact_us">
              <h4>联系我们</h4>
              <li v-for="(item, key) in Contact" :key="key">
                {{ item }}
              </li>
            </ul>
          </el-col>
        </el-row>
      </div>
      <!-- <div id="map-container"></div> -->
    </div>
    <!-- <div class="footer_back">
      <p>©2022 荣猿科技 <a href="https://beian.miit.gov.cn" target="_blank" style="color: #999;" one-link-mark="yes">粤ICP备XXXXXX号</a></p>
    </div> -->
  </div>
</template>

<script>
// import AMapLoader from "@amap/amap-jsapi-loader"; // 按需引入依赖
export default {
  data() {
    return {
      map: [113.867425, 22.597247],
      mouseTool: null,
      anchor: {
        banner: "HOME",
        advantage: "服务优势",
        pricing: "服务价格",
        container: "集成平台",
        foote: "关于我们",
      },
      Contact: [
        "中国·深圳·宝安",
        "西城工业区红山大厦523",
        "T:18165766512",
        "M:8618165766512",
        "E:info@honorape.com",
      ],
    };
  },
  mounted() {
    // this.initMap();
  },
  methods: {
    // 初始化地图
    initMap() {
      AMapLoader.load({
        // key: "4b5ca90e8e1e7821f8ab35a4995107d7", // 2.0
        key: "3a9854d696962362b4b264045e8cd823", // 2.0
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          "AMap.Scale",
          "AMap.ToolBar",
          "AMap.MouseTool",
          "AMap.Marker",
          "AMap.LngLat",
        ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.map = new AMap.Map("map-container", {
            viewMode: "2D", //  是否为3D地图模式
            zoom: 16, // 初始化地图级别
            center: [113.867425, 22.597247], //中心点坐标
            resizeEnable: true,
          });
          // 添加左下角的比例尺
          this.map.addControl(new AMap.Scale());
          let toolBar = new AMap.ToolBar({
            position: {
              bottom: "210px",
              right: "35px",
            },
          });
          this.mouseTool = new AMap.MouseTool(this.map);
          this.map.addControl(toolBar); // 添加右下角的放大缩小

          var marker = new AMap.Marker({
            position: new AMap.LngLat(113.867425, 22.597247), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: "荣猿科技有限公司",
          });

          // 将创建的点标记添加到已有的地图实例：
          this.map.add(marker);
        })
        .catch((e) => {
        });
    },
    scrollDom(active) {
      this.$emit("scrollDom", active);
    },
  },
};
</script>

<style lang="less" scoped>
.footer {
  margin-top: 50px;
  height: 400px;
  // background-color: #fafafa;
  background-color: #222328;
  color: #eee;
  // padding-top: 106px;
  position: relative;
  overflow: hidden;
  .content {
    width: 1200px;
    margin: 0px auto;
    padding: 50px 0;
    color: #eee;
    .el-row{
    display: flex;
    justify-content: center;
    align-items: center;
    }
    .logo {
      font-size: 30px;
    }
    a {
      color: #eee;
      text-decoration: none;
    }
    li {
      margin: 10px 0;
      list-style: none;
    }
    .footer_header {
      margin-left: 80px;
      padding: 0;
      display: flex;
      justify-content: center;
      //   .footer-list{
      //   li{
      //     margin: 10px 0;
      //     list-style: none;
      //   }
      // }
    }
    .About_us {
      display: flex;
      justify-content: center;
      //   .footer-list{
      //   li{
      //     margin: 10px 0;
      //     list-style: none;
      //   }
      // }
    }
  }
}
.footer_back {
  color: #999;
  width: 100%;
  display: block;
  background-color: #1d1e20;
  padding: 15px 0 10px;
  text-align: center;
  p {
    margin: 0px;
  }
  a{
    text-decoration: none;
    
  }
}
#map-container {
  overflow: hidden;
  width: 380px;
  height: 380px;
  position: absolute;
  top: 10px;
  right: 10px;
  margin: 0;
}
</style>
