<template>
  <div class="Nav">
    <el-menu class="elMenu" :default-active="activeIndex" mode="horizontal" @select="handleSelect">
      <el-row type="flex" justify="center">
        <el-col :span="6">
          <div class="logo" @click="handleSelect(1)">
            <img src="../assets/logo.png" alt="" />
          </div>
        </el-col>
        <el-col :span="23" :offset="6">
          <el-menu-item index="1">首页</el-menu-item>
          <el-menu-item index="2">服务优势</el-menu-item>
          <el-menu-item index="3">服务价格</el-menu-item>
          <el-menu-item index="4">集成平台</el-menu-item>
          <!-- <el-menu-item index="4" @click="$router.push('/Application')">申请试用</el-menu-item> -->
          <el-menu-item index="5" @click="link()">申请试用</el-menu-item>
        </el-col>
        <el-col :span="4" type="flex">
          <!-- <el-link icon="el-icon-user-solid" href="baidu.com">登陆</el-link> -->
        </el-col>
        <el-col :span="2" type="flex">
          <el-button class="applyBtn" type="primary" size="mini" round @click="applyBtn">登录</el-button>
        </el-col>
      </el-row>
    </el-menu>
  </div>
</template>

<script>

export default {
  name: "HomeView",
  data() {
    return {
      activeIndex: "1",
    };
  },
  created() {
  },
  methods: {
    handleSelect(key, keyPath) {
      let active = [
        'banner',
        'advantage',
        'pricing',
        "container",
      ]
      if (key == 5) return
      this.$emit('scrollDom', active[key - 1])
    },
    link() {
      window.open(`#/Application`, "_blank"); // 新窗口打开外链接
    },
    applyBtn() {
      this.$message({
        type: "warning", // success error warning
        message: "设备不在白名单请申请后重试",
        duration: 2000,
        customClass: 'global-messageZindex',
      });
    },
    sonActive(index) {
      this.activeIndex = index
    }
  },
};
</script>

<style lang="less">
.global-messageZindex {
  z-index: 99999 !important;
}
</style>
<style lang="less" scoped>
.Nav {
  box-shadow: 0px 2px 10px 12px #0000000f !important;
  margin-bottom: -1px;
  z-index: 9999 !important;
  position: sticky !important;
  /* 24是body的paddingTop */
  top: 0px;
}

// .elMenu {
//   z-index: important;
//   position: sticky !important;
//   /* 24是body的paddingTop */
//   top: -1px;
// }

.logo {
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  // margin: 4px;
  img {
    width: 22%;
    height: 100%;
  }
}

/deep/ .el-menu-item {
  float: left;
}

/deep/ .el-submenu__title {
  float: left;
  width: 126px;
}

.applyBtn {
  margin: 14px 0 0 6px;
}
</style>