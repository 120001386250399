<template>
  <div class="container">
    <div>
      <div class="row">
        <div class="col-sm-12">
          <div class="section_title">
            <i class="fa el-icon-s-shop"></i>
            <h3 class="fadeIn animated wow">集成平台</h3>
            <h6 class="fadeIn animated wow">
              已集成众多电商平台、物流快递公司等系统.
            </h6>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="row">
          <el-row :gutter="20">
            <el-col :span="5" v-for="(item, index) in card" :key="index" class="bgc">
              <el-card class="forCard">
                    <img :src="item" alt="" style="width: 100%; height: 100%;" >
              </el-card>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      card: [
       require('@/assets/amazon.png'),
       require('@/assets/ebay.png'),
       require('@/assets/shopify.png'),
       require('@/assets/woocommerce.png'),
       require('@/assets/walmart.png'),
       require('@/assets/wayfair.png'),
       require('@/assets/wish.png'),
       require('@/assets/paypal.png'),
       require('@/assets/DHl.png'),
       require('@/assets/ups.png'),
       require('@/assets/fedex.png'),
       require('@/assets/ems.png'),
       require('@/assets/dpd.png'),
       require('@/assets/gls.png'),
       require('@/assets/postpony.png'),
       require('@/assets/stamps.png'),
      ],
      isActive: false,
      cindex: null
    };
  },
  methods: {
    enter(index) {
      this.isActive = true;
      this.cindex=index
    },
    leave(index) {
      this.isActive = false;
      this.cindex=null
    },
  },
};
</script>

<style lang="less" scoped>
.advantage {
  width: 1200px;
  margin: 50px auto;
}
.row {
  // margin-right: -15px;
  // margin-left: -15px;
  .col-sm-12 {
    width: 100%;
    .section_title {
      text-align: center;
      i {
        color: #0099ff;
        font-size: 36px;
        // margin-bottom: 20px;
      }
      .fa {
        display: inline-block;
        font-family: FontAwesome;
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      h3 {
        font-weight: 500;
        font-size: 32px;
        font-family: "Roboto", sans-serif;
      }
      .fadeIn {
        -webkit-animation-name: fadeIn;
        animation-name: fadeIn;
      }
      .animated {
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
      }
    }
  }
}
.card {
  margin-left: 86px;
  margin-right: -100px;
  .row {
    // margin-left: 20px;
    text-align: center;
    width: 105%;
    .bgc{
      background-color: #fff;
      .forCard {
      height: 210px;
      margin: 15px 0px;
      text-align: center;
      i {
        font-size: 30px;
      }
    }
    }
  }
}
.active {
  color: #0099ff;
}

.el-card.is-always-shadow, .el-card.is-hover-shadow, .el-card.is-hover-shadow{
  box-shadow: none;
  border: transparent;
}

</style>