<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>

export default {
  name: "app",
  data() {
    return {};
  },
  components: {
  },
};
</script>
<style lang="less">
body {
  background-color: #f9fcff;
  padding: 0;
  margin: 0px 0px 0px -1px;
}

.Nav {
  box-shadow: 0px 2px 10px 12px #0000000f !important;
  margin-bottom: -1px;
  z-index: 99999 !important;
  position: sticky !important;
  /* 24是body的paddingTop */
  top: 0px;
}
</style>
