<template>
  <div class="home">
    <Navmenu class="nav  anchorPoint" @scrollDom="scrollDom" ref="child"></Navmenu>
    <el-card class="banner anchorPoint" id="banner">
      <div class="bg-overlay opacity-6">
        <div class="pt150 pb120" style="margin-top: 100px">
          <div class="container">
            <h1 class="animated fadeInDown wow animated" style="font-size: 38px">
              科技让物流更简单
            </h1>
            <p class="animated fadeInDown wow animated ml0 mr0" style="max-width: none" data-wow-delay=".2s">
              荣猿致力于为跨境电商物流企业提供智慧物流系统，自动化物流设备，标准化操作方案，助力传统跨境电商物流转型升级，打造更现代化的智慧物流网络。
            </p>
          </div>
        </div>
      </div>
    </el-card>
    <advantage class="anchorPoint" id="advantage" />
    <pricing class="anchorPoint" id="pricing" />
    <container id='container' />
    <foote id="foote" @scrollDom="scrollDom"></foote>
  </div>
</template>

<script>
import Navmenu from "@/components/NavMenu.vue";
import advantage from "@/components/advantage.vue";
import pricing from "@/components/pricing.vue";
import foote from "@/components/footer.vue";
import container from "@/components/container.vue"
export default {
  name: "HomeView",
  data() {
    return {};
  },
  components: {
    advantage,
    Navmenu,
    pricing,
    foote,
    container,
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll, false)
  },
  destroyed() {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    scrollDom(active) {
      const scrollDom = document.getElementById(active);
      if (active == 'banner') {
        scrollDom.scrollIntoView(0); return
      }
      scrollDom.scrollIntoView();
    },
    onScroll() {
      const navContents = document.querySelectorAll('.anchorPoint')
      // 所有锚点元素的 offsetTop
      const offsetTopArr = []
      navContents.forEach(item => {
        offsetTopArr.push(item.offsetTop)
      })
      // 获取当前文档流的 scrollTop
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      // 定义当前点亮的导航下标
      let navIndex = 0
      for (let n = 0; n < offsetTopArr.length; n++) {
        if (scrollTop >= offsetTopArr[n]) {
          navIndex = n+1
        }
      }
      this.$refs.child.sonActive(navIndex.toString())
    }
  },
};
</script>

<style lang="less">
.banner {
  height: 597px;
  background: url("../assets/banner.jpg") no-repeat;
  background-size: 100% 100%;
  position: relative;
}

.bg-overlay {
  position: absolute;
  width: 100%;
  // height: calc(100% - 64px);
  height: 100%;
  top: 0px;
  left: -1px;
  background-color: rgba(0, 0, 0, 0.6);
}

.pb120 {
  padding-bottom: 120px;
}

.pt150 {
  padding-top: 150px;
}

.container {
  width: 1200px;
  margin: auto;

  h1 {
    color: #ffffff;
    -webkit-font-smoothing: antialiased;
    text-transform: capitalize;
    margin-bottom: 15px;
    font-weight: 400;
  }

  p {
    line-height: 22px;
    color: #ffffff;
    letter-spacing: 1px;
    margin: 30px auto;
    text-transform: capitalize;
    font-weight: 400;
    max-width: 800px;
  }

  .animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  .fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
  }
}
</style>
