<template>
  <div class="advantage">
    <div>
      <div class="row">
        <div class="col-sm-12">
          <div class="section_title">
            <i class="fa el-icon-s-shop"></i>
            <h3 class="fadeIn animated wow">服务优势</h3>
            <h6 class="fadeIn animated wow">
              我们每天想的就是如何让事情更简单！
            </h6>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="row">
          <el-row :gutter="20">
            <el-col :span="7" v-for="(item, index) in card" :key="index">
              <el-card class="forCard">
                <div @mouseenter="enter(index)" @mouseleave="leave(index)">
                  <div :class="[isActive&& cindex==index ? 'active' : '' ]">
                    <i :class="item.icon"></i>
                    <h5>{{ item.title }}</h5>
                  </div>
                  <div>
                    {{ item.content }}
                  </div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      card: [
        {
          icon: "el-icon-s-tools",
          title: "智能系统",
          content:
            "系统依简单、高效、智能化理念设计，更标准的工作流程，更轻松的工作方式，同时带给客户更好的服务体验",
        },
        {
          icon: "el-icon-help",
          title: "自动化设备",
          content:
            "使用智能终端、信息化操作台、自动化流水线、打标换标系统等配套智能设备，节约人力成本，提高准确率及操作效率",
        },
        {
          icon: "el-icon-user",
          title: "定期免费培训",
          content:
            "每月两次的定期强化培训，提升员工系统思维，平时一对一的培训及客服支持，及时解决系统使用问题，最大化系统使用价值",
        },
        {
          icon: "el-icon-medal-1",
          title: "专业研发团队",
          content:
            "每月两次的定期强化培训，提升员工系统思维，平时一对一的培训及客服支持，及时解决系统使用问题，最大化系统使用价值",
        },
        {
          icon: "el-icon-document",
          title: "创新的方案",
          content:
            "更适合电商物流，更互联网，更创新的跨境物流应用方案，紧跟信息时代步伐，给客户更好的服务",
        },
        {
          icon: "el-icon-chat-line-round",
          title: "专业持续的支持",
          content:
            "我们卖的不是系统，不是设备，卖的是服务，一切服务按需按月付费，永远不会有一手交钱一手交货后无售后的问题",
        },
      ],
      isActive: false,
      cindex: null
    };
  },
  methods: {
    enter(index) {
      this.isActive = true;
      this.cindex=index
    },
    leave(index) {
      this.isActive = false;
      this.cindex=null
    },
  },
};
</script>

<style lang="less" scoped>
.advantage {
  width: 1200px;
  margin: 50px auto;
}
.row {
  // margin-right: -15px;
  // margin-left: -15px;
  .col-sm-12 {
    width: 100%;
    .section_title {
      text-align: center;
      i {
        color: #0099ff;
        font-size: 36px;
        // margin-bottom: 20px;
      }
      .fa {
        display: inline-block;
        font-family: FontAwesome;
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      h3 {
        font-weight: 500;
        font-size: 32px;
        font-family: "Roboto", sans-serif;
      }
      .fadeIn {
        -webkit-animation-name: fadeIn;
        animation-name: fadeIn;
      }
      .animated {
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
      }
    }
  }
}
.card {
  margin-left: 86px;
  margin-right: -100px;
  .row {
    // margin-left: 20px;
    text-align: center;
    .forCard {
      height: 210px;
      margin: 15px 0px;
      text-align: center;
      i {
        font-size: 30px;
      }
    }
  }
}
.active {
  color: #0099ff;
}
</style>